import React, { lazy, Suspense } from "react";
// import BlankPage from "../pages/blank";
import Home from "../assets/layout/home.png";
import MyPortfolio from "../assets/layout/myportfolio.png";
import IssuerBoard from "../assets/layout/issuerboard.png";
import Marketplace from "../assets/layout/marketplace.png";
import HomeActive from "../assets/layout/home-active.png";
import MyPortfolioActive from "../assets/layout/myportfolio-active.png";
import IssuerBoardActive from "../assets/layout/issuerboard-active.png";
import MarketplaceActive from "../assets/layout/marketplace-active.png";

import projectListIcon from "../assets/icon/projectList.svg";
import manageIssuerAccountIcon from "../assets/icon/manageIssuerAccount.svg";
import sharesTypeIcon from "../assets/icon/sharesType.svg";
import metaKeyIcon from "../assets/icon/metaKey.svg";


// 懒加载、防闪屏
const lazyLoad = (src) => (
  <Suspense fallback={<>...</>}>{React.createElement(lazy(src))}</Suspense>
);
//路由配置
const routes = [
  {
    path: "/",
    element: lazyLoad(() => import("../layout/index")),
    children: [
      {
        path: "/",
        element: lazyLoad(() => import("../pages/brokerAccounts")),
        name: "Add Broker Dealer",
        icon: manageIssuerAccountIcon,
        activeIcon: manageIssuerAccountIcon,
        role: "superAdmin",
      },
      {
        path: "/project-list",
        element: lazyLoad(() => import("../pages/brokerProjects")),
        name: "Manage Broker Dealer Projects",
        icon: manageIssuerAccountIcon,
        activeIcon: manageIssuerAccountIcon,
      },
      {
        path: "/investment-list",
        element: lazyLoad(() => import("../pages/brokerInvestments")),
        name: "Manage Investment",
        icon: manageIssuerAccountIcon,
        activeIcon: manageIssuerAccountIcon,
      },
      {
        path: "/investor-accounts",
        element: lazyLoad(() => import("../pages/investorAccounts")),
        name: "Manage Investor Accounts",
        icon: manageIssuerAccountIcon,
        activeIcon: manageIssuerAccountIcon,
      },
    ],
  },
  {
    path: "/",
    element: lazyLoad(() => import("../layout/layout-no-side-bar")),
    hidden: true,
    children: [
      {
        path: "/new-broker/:id",
        element: lazyLoad(() => import("../pages/newBroker")),
        name: "New Broker Dealer",
        hidden: true,
      },
      {
        path: "/new-project/:id",
        element: lazyLoad(() => import("../pages/newProject")),
        name: "New Project",
        hidden: true,
      },
      {
        path: "/meta-value/:id",
        element: lazyLoad(() => import("../pages/metaValue")),
        name: "Meta Value",
        hidden: true,
      },
      {
        path: "/new-issuer/:id",
        element: lazyLoad(() => import("../pages/newIssuer")),
        name: "New Issuer",
        hidden: true,
      },

      {
        path: "/shares",
        element: lazyLoad(() => import("../pages/shares")),
        name: "Shares",
        hidden: true,
      },

      {
        path: "/new-shares/:id",
        element: lazyLoad(() => import("../pages/newShares")),
        name: "New Shares",
        hidden: true,
      },
      {
        path: "/new-shares-type/:id",
        element: lazyLoad(() => import("../pages/newSharesType")),
        name: "New Shares Type",
        hidden: true,
      },

      {
        path: "/new-meta-key/:id/:key",
        element: lazyLoad(() => import("../pages/newMetaKey")),
        name: "New Meta Key",
        hidden: true,
      },
    ],
  },
  {
    path: "/login",
    element: lazyLoad(() => import("../pages/auth/login/index")),
  },
  {
    path: "/kyc/:accesstoken",
    element: lazyLoad(() => import("../pages/auth/kyc/index")),
  },
  {
    path: "/forget-password",
    element: lazyLoad(() => import("../pages/auth/forgetPassword/index")),
  },
  {
    path: "/sign-up",
    element: lazyLoad(() => import("../pages/auth/signUp/index")),
  },
  {
    path: "/sign-up-investor",
    element: lazyLoad(() => import("../pages/auth/signUpInvestor/index")),
  },

  {
    path: "/sign-up-success",
    element: lazyLoad(() => import("../pages/auth/signUpSuccess/index")),
  },
];
export default routes;
